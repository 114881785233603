import dayjs from 'dayjs';
import { Weekday } from '@models/Weekdays';
import type { Nullable } from '@models/CustomUtilityTypes';
import type { DayDefinition } from '@gql/schema';

/**
 * Converts a date string or Date object to the current weekday.
 * @param dateInput - The date string or Date object to convert.
 * @returns The weekday corresponding to the date input.
 */
export function getWeekdayFromDate(dateInput: string | Date): Weekday {
  const date = dayjs(dateInput);
  const weekdayStr = date.format('dddd');

  switch (weekdayStr) {
    case 'Monday':
      return Weekday.Monday;
    case 'Tuesday':
      return Weekday.Tuesday;
    case 'Wednesday':
      return Weekday.Wednesday;
    case 'Thursday':
      return Weekday.Thursday;
    case 'Friday':
      return Weekday.Friday;
    case 'Saturday':
      return Weekday.Saturday;
    case 'Sunday':
      return Weekday.Sunday;
    default:
      throw new Error(`Invalid weekday: ${weekdayStr}`);
  }
}

export function getWeekdayFromDayDefinition(
  dayDef: Nullable<DayDefinition>
): Nullable<Weekday> {
  if (isEmpty(dayDef)) return null;

  switch (dayDef?.id) {
    case 1:
      return Weekday.Monday;
    case 2:
      return Weekday.Tuesday;
    case 3:
      return Weekday.Wednesday;
    case 4:
      return Weekday.Thursday;
    case 5:
      return Weekday.Friday;
    case 6:
      return Weekday.Saturday;
    case 7:
      return Weekday.Sunday;
    default:
      return null;
  }
}

export function getWeekdayFromId(id: number): Nullable<Weekday> {
  switch (id) {
    case 1:
      return Weekday.Monday;
    case 2:
      return Weekday.Tuesday;
    case 3:
      return Weekday.Wednesday;
    case 4:
      return Weekday.Thursday;
    case 5:
      return Weekday.Friday;
    case 6:
      return Weekday.Saturday;
    case 7:
      return Weekday.Sunday;
    default:
      return null;
  }
}
